<template>
    <div class="rules-warp">
        <div class="rules-content">
            <div class="rules-header"></div>
            <div class="rules-list-content">
                <div class="rules-list">
                    <h2>活动流程：</h2>
                    <p>1、复制邀请链接，发送给好友</p>
                    <p>2、好友打开链接，填写相关信息，并提交申请体验</p>
                    <p>3、好友沃创云平台账户充值并消费（购买平台任一套餐服务）</p>
                    <p>4、您随即可享受该好友消费金额的10%佣金分成</p>
                </div>
                <div class="rules-list">
                    <h2>活动规则：</h2>
                    <p>1、邀请的好友完成充值后，系统随即绑定其为您的分佣好友，该好友未来3个月内每笔平台消费，您均可获得10%佣金分成</p>
                    <p>2、“待返佣金”指好友实际充值总金额的10%分佣；“可提现佣金”指好友在沃创云平台实际消费金额的10%分佣</p>
                    <p>3、分佣好友人数没有上限，您邀请的好友越多，享受的分佣则越多</p>
                    <p>4、为防止恶意刷单，“可提现佣金”实际到账时间及金额，以分佣好友所购买的外呼系统、优选商品等产品消费完毕或到期为准；如好友发生退款行为，则相应佣金根据实际消费或到期金额折算更新</p>
                </div>
                <div class="rules-list">
                    <p>活动最终解释权归沃创云所有</p>
                    <p> 若有疑问，请联系客服：</p>
                    <p>WX： 13711109171</p>
                </div>
                <div class="rules-link">
                    <div>
                        <div class="rules-link-title">邀请链接：</div>
                        <div class="rules-link-item">
                            <div class="link-content">{{hotUrl}}</div>
                            <div class="copy copy-link" :data-clipboard-text="hotUrl">复制</div>
                        </div>
                        <!-- <div class="rules-link-title">我的邀请码：</div>
                        <div class="rules-link-item">
                            <div class="code">{{userInfo.invite_code}}</div>
                            <div class="copy copy-code" :data-clipboard-text="userInfo.invite_code">复制</div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { getHotUrl } from '@/utils/common'
    import { getStorage } from '@/utils/common'
    import Clipboard from 'clipboard';
    export default {
        data() {
            return {
                hotUrl: '',
            }
        },
        computed: {
            ...mapState([ 'userInfo' ])
        },
        created() {
            const userId = getStorage('userId')
            if(!userId) {
                const token = getStorage('token')
                this.hotUrl = `${getHotUrl(this.$route)}/teamwork?token=${token}`
            } else {
                const type = getStorage('systemType')
                this.hotUrl = `${getHotUrl(this.$route)}/teamwork?userId=${userId || 0}&system_type=${type}`
                this.$store.dispatch('getUserInfo')
            }
        },
        mounted() {
            // const clipboardCode = new Clipboard('.copy-code');
            // clipboardCode.on('success', () => {
            //     this.$successPrompt('复制成功')
            // });
            const clipboard = new Clipboard('.copy-link');
            clipboard.on('success', ()=> {
                this.$successPrompt('复制成功')
            });
        }
    }
</script>

<style lang="scss" scoped>
.rules-warp{
    @include flex();
    padding: 30px 0;
    min-height: 100%;
    background: $main_bg_color;
    min-width: 1200px;
    .rules-content{
        @include wh(1000px, 900px);
        border-radius: 12px;
        box-shadow: 0px 30px 60px rgba(41, 76, 172, 0.2);
        background: #fff;
        .rules-header{
            height: 68px;
            background: url('../assets/image/rules_bg.png');
            background-size: 100% 100%;
            margin-bottom: 35px;
        }
        .rules-list{
            padding: 0 80px;
            margin-bottom: 24px;
            h2{
                @include font(16px,$main_font_color);
                margin-bottom: 12px;
            }
            p{
                @include font(16px,#697085);
                margin-bottom: 12px;
            }
        }
        .rules-link{
            margin: auto;
            margin-top: 35px;
            @include wh(660px,246px);
            background: url('../assets/image/share_min.png') no-repeat;
            background-size: 100% 100%;
            padding: 0px 60px 0 60px;
            @include flex();
            .rules-link-title{
                @include font(28px,#fff);
                opacity: 0.5;
                margin-bottom: 20px;
            }
            .rules-link-item{
                // @include flex(center,space-between);
                @include font(28px,#fff);
                border-bottom: 2px solid rgba(255, 255, 255, 0.1);
                padding-bottom: 29px;
                margin-bottom: 32px;
                &:last-child{
                    margin-bottom: 0;
                    border-bottom:none;
                    padding-bottom: 0;
                }
                .link-content{
                    flex: 1;
                    @include overflow(2);
                }
                .code{
                    flex: 1;
                }
                .copy{
                    @include wh(126px,46px);
                    @include font(24px,#fff,center);
                    line-height: 44px;
                    border: 2px solid rgba(255, 255, 255, 0.5);
                    border-radius: 40px;
                    cursor: pointer;
                    margin: auto;
                    margin-top: 20px;
                }
            }
        }
    }
}
</style>